import {
  parsePhoneNumber,
  isValidPhoneNumber,
  AsYouType
} from "libphonenumber-js";
import metadata from "@/app/javascript/utils/libphonenumber-metadata.custom.json";
import { on, ready } from "../utils/listeners";
import api from "../api/promotionSignUp";

const hideSignUpDiv = location => {
  const signupStateDiv = document.querySelector(`.js-signup-state-${location}`);
  signupStateDiv.classList.add("hidden");
};

const hideHeaderDiv = () => {
  const headerDiv = document.querySelector(".js-header-wrapper");
  headerDiv.classList.add("hidden");
};

const showSuccessDiv = location => {
  const successStateDiv = document.querySelector(
    `.js-success-state-${location}`
  );

  successStateDiv.classList.remove("hidden");
};

const updateSuccessClasses = location => {
  hideSignUpDiv(location);
  hideHeaderDiv();
  showSuccessDiv(location);
};

const showRegistrationPopup = email => {
  const registrationPopup = document.querySelector("#email-signup-form-popup");
  registrationPopup.classList.remove("hidden");

  const emailRegisterField = document.querySelector(".email-input");
  if (emailRegisterField) {
    emailRegisterField.value = email;
  }
};

const hideSignUpPopup = () => {
  const registrationPopup = document.querySelector("#email-signup-form-popup");
  const emailSignUp = document.querySelector("#email-pop-up-container");

  registrationPopup.classList.remove("hidden");
  emailSignUp.classList.add("hidden");
};

const showRegistrationForm = email => {
  window.showManualRegisterForm(email);

  const signUpForm = document.querySelector("#email-signup-form-inline");
  signUpForm.classList.remove("hidden");

  const emailRegisterField = document.querySelector("#register-modal-email");
  if (emailRegisterField) {
    emailRegisterField.value = email;
  }
};

const updateRegistrationClasses = (email, location) => {
  hideSignUpDiv(location);
  showRegistrationForm(email);
};

const showRegistrationStep = (email, location) => {
  if (location === "block") {
    updateRegistrationClasses(email, location);
  } else if (location === "footer") {
    hideSignUpDiv(location);
    showSuccessDiv(location);
    showRegistrationPopup(email);
  } else {
    hideSignUpPopup();
    showRegistrationPopup(email);
  }
};

const showSuccessState = location => {
  if (!document.startViewTransition) {
    updateSuccessClasses(location);
  } else {
    document.startViewTransition(() => {
      updateSuccessClasses(location);
    });
  }
};

const showWaitingListSuccessMessage =
  window.localStorage.getItem("showRegistrationSuccess") === "true";

if (showWaitingListSuccessMessage) {
  updateSuccessClasses("block");
  window.localStorage.removeItem("showRegistrationSuccess");
}

// If the user is logged in, default all email sign ups to the current user's
// email address
ready(() => {
  if (window.currentUser) {
    const signUps = document.querySelectorAll(
      ".js-email-signup input[type=email]"
    );

    signUps.forEach(signup => {
      signup.value = window.currentUser.email;
    });
  }
});

on("submit", ".js-promotion-signup", async (el, e) => {
  e.preventDefault();

  const {
    segmentKey,
    inputType,
    downloadFile,
    downloadName,
    analyticsLocation,
    waitList
  } = el.dataset;

  const isWaitList = waitList === "true";

  let location;
  if (analyticsLocation === "Footer") {
    location = "footer";
  } else if (
    analyticsLocation === "Wedding Pop Up" ||
    analyticsLocation === "Pop Up"
  ) {
    location = "popup";
  } else {
    location = "block";
  }

  const setupDownload = () => {
    if (downloadFile && downloadName) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = downloadFile;
      link.download = downloadName;
      link.click();
    }
  };

  const setInvalidNumberError = () => {
    const error = document.querySelector(".js-sms-error");
    error.classList.add("show-error");

    error.innerHTML = "Please enter a valid US cell phone number";
  };

  const setAlreadySubscribedError = message => {
    const error = document.querySelector(`.js-sms-error-${location}`);
    error.classList.add("show-error");

    error.innerHTML = message;
  };

  const hideError = () => {
    const error = document.querySelector(`.js-sms-error-${location}`);
    error.classList.remove("show-error");
  };

  const weddingDateInput = el.querySelector("input[type=date]");
  const weddingDate = weddingDateInput ? weddingDateInput.value : null;

  if (inputType === "email") {
    const email = el.querySelector("input[type=email]").value;
    api
      .emailSignUp({
        email,
        weddingDate,
        signup_location: analyticsLocation,
        campaign_key: segmentKey,
        wait_list: isWaitList
      })
      .then(response => {
        if (response.success) {
          setupDownload();
          if (response.anonymous_user) {
            showRegistrationStep(email, location);
          } else {
            showSuccessState(location);
          }
        } else {
          setAlreadySubscribedError(response.message);
        }
      });
  } else {
    const telNumber = el.querySelector("input[type=tel]");
    const telNumberValue = telNumber.value;

    const { number } = parsePhoneNumber(telNumberValue, "US", metadata);
    if (isValidPhoneNumber(number)) {
      hideError();
      api
        .smsSignUp({
          phone: telNumberValue,
          weddingDate,
          campaign_key: segmentKey
        })
        .then(setupDownload);
      analytics.track("Signed up to SMS", {
        location: analyticsLocation
      });
      showSuccessState(location);
    } else {
      telNumber.classList.add("error");
      setInvalidNumberError();
    }
  }
});

on("input", ".js-email-or-sms-input", (el, e) => {
  const number = e.target.value;
  let phoneNumber = number;

  try {
    /**
     * If phone number is in: (123) condition, it remove end char ) and returns.
     * 4 is satisfied for: (123) condition and 6 is satisfied in: 1 (234) 	condition.
     */
    if (phoneNumber.length === 4 || phoneNumber.length === 6) {
      phoneNumber.slice(0, -1);
    }
    phoneNumber = new AsYouType("US", metadata).input(number);
  } catch {
    console.log("Fail silently");
  }

  e.target.value = phoneNumber;
});

on("click", "#promo_banner_link", (target, e) => {
  if (target.href.includes("#main-site-signup-footer")) {
    e.preventDefault();
    const footer = document.querySelector("#main-site-signup-footer");
    footer.scrollIntoView({ behavior: "smooth" });
  }
});
