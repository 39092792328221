<template>
  <div class="pb-8">
    <overlay
      v-show="loading"
      is-drawer-overlay
      absolutely-positioned />
    <div class="bg-paper-1">
      <div class="flex items-center justify-between">
        <span class="typeset-8 text-ink-4">{{ numberOfItemsString }}</span>
      </div>
      <div v-if="!loggedIn && !loading && listings.length">
        <p class="typeset-7 mb-5 mt-4">
          {{ t("favourites.drawer.log-in.description") }}
        </p>
        <button
          class="btn btn-large btn-primary btn-block w-full mb-6"
          @click="showLoginModal">
          {{ t("favourites.drawer.log-in.cta") }}
        </button>
      </div>
      <div
        v-if="listings.length"
        class="bg-paper-1 grid grid-cols-2 sm:grid-cols-4 md:grid-cols-2 gap-x-4 gap-y-6 pb-3 mt-4">
        <listing
          v-for="(listing, index) in listings"
          :key="index"
          :listing="listing"
          :show-stock-status="false"
          favourited
          disable-favourites
          image-bg-class="bg-paper-3">
          <template #action>
            <a
              class="underline cursor-pointer inline-flex"
              @click="removeFavourite(listing)">
              {{ t('favourites.drawer.remove') }}
            </a>
          </template>
        </listing>
      </div>
      <div
        v-else-if="!loading"
        class="flex flex-col items-center mt-4">
        <img
          class="aspect-ratio-1x1 mb-6"
          width="240"
          :src="emptyFavouritesImage" />
        <p class="typeset-7 mb-6 mt-3">
          {{ t("favourites.drawer.empty.description") }}
        </p>
        <button
          class="btn btn-large btn-primary btn-block w-full mt-3"
          @click="$emit('closeDrawer')">
          {{ t("favourites.drawer.keep-shopping") }}
        </button>
      </div>
      <a
        v-if="showMoreButton"
        class="btn btn-primary btn-large btn-block mt-6"
        href="#"
        @click.prevent="loadMore">
        {{ t("favourites.drawer.load-more") }}
      </a>
    </div>
  </div>
</template>

<script>
import emptyFavouritesImage from "@/app/javascript/images/empty-favourites.png";
import api from "../../api";
import favouritesApi from "../../api/favourite";

import Listing from "../shared/Listing.vue";
import Overlay from "../shared/Overlay.vue";

export default {
  name: "MiniFavouritesListings",
  components: {
    Listing,
    Overlay
  },
  props: {
    hideCloseButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ["closeDrawer"],
  data() {
    return {
      loading: true,
      listings: [],
      page: 1,
      showMoreButton: false,
      loggedIn: false,
      emptyFavouritesImage,
      totalFavourites: 0
    };
  },
  computed: {
    numberOfItemsString() {
      if (this.totalFavourites === 1) {
        return this.t("favourites.drawer.number-of-items.single");
      }
      return this.t("favourites.drawer.number-of-items.multiple", {
        number: this.totalFavourites
      });
    }
  },
  mounted() {
    this.fetchFavourites();
    this.loggedIn = window.userLoggedIn;
  },
  methods: {
    showLoginModal() {
      const showLoginModalEvent = new Event("showLoginModal");
      window.dispatchEvent(showLoginModalEvent);
      this.$emit("closeDrawer");
    },
    loadMore() {
      this.page += 1;
      this.fetchFavourites();
    },
    fetchFavourites() {
      this.loading = true;
      api.get(`/favourites?page=${this.page}`).then(({ data }) => {
        this.loading = false;
        this.showMoreButton = data.more_pages;
        this.listings = [...this.listings, ...data.listings];
        this.totalFavourites = data.total;
      });
    },
    removeFavourite(listing) {
      this.removeFromListings(listing);
      window.resetFavourites(listing.type, listing.id);
      favouritesApi.toggle(listing);
      api.get(`/favourites?page=${this.page}`).then(({ data }) => {
        this.showMoreButton = data.more_pages;
      });
    },
    removeFromListings(deletedListing) {
      this.listings = this.listings.filter(listing => {
        return (
          listing.id !== deletedListing.id ||
          listing.type !== deletedListing.type
        );
      });
    }
  }
};
</script>
