import { ready } from "../utils/listeners";

const countDownClock = () => {
  const d = document;
  const countdownElement = document.querySelector(".js-countdown-block");
  let setOpacity;

  if (!countdownElement) return;

  function timeDifference(timestamp) {
    const dateInFuture = Date.parse(timestamp);

    const dateNow = Date.now();

    const newDate = (dateInFuture - dateNow) / 1000;

    return Math.trunc(newDate);
  }

  const daysElement = d.querySelector(".js-countdown-block .days");
  const hoursElement = d.querySelector(".js-countdown-block .hours");
  const minutesElement = d.querySelector(".js-countdown-block .minutes");
  const secondsElement = d.querySelector(".js-countdown-block .seconds");
  const liElement = d.querySelectorAll(".js-countdown-block li");

  function formatNum(number) {
    return number.toString().padStart(2, "0");
  }

  function displayTimeLeft(seconds) {
    daysElement.textContent = formatNum(Math.floor(seconds / 86400));
    hoursElement.textContent = formatNum(Math.floor((seconds % 86400) / 3600));
    minutesElement.textContent = formatNum(
      Math.floor(((seconds % 86400) % 3600) / 60)
    );
    secondsElement.textContent = formatNum(
      seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60
    );

    if (setOpacity) {
      liElement.forEach(li => {
        li.classList.add("opacity-100");
      });
    }

    setOpacity = true;
  }

  let countdown;

  function timer() {
    countdown = setInterval(() => {
      const secondsLeft = timeDifference(countdownElement.dataset.secondsLeft);

      if (secondsLeft < 0) {
        clearInterval(countdown);
        return;
      }

      displayTimeLeft(secondsLeft);
    }, 1000);
  }

  timer();
};

ready(() => {
  countDownClock();
});
