<template>
  <manual-register-form
    :signup-location="signupLocation"
    :user="user"
    hide-checkbox
    @submit-register-form="submitRegisterForm" />
</template>

<script setup>
import { inject, reactive } from "vue";
import { storeToRefs } from "pinia";
import useUserStore from "../../stores/user-store";
import ManualRegisterForm from "../shared/ManualRegisterForm.vue";
import validDateOfBirth from "../../utils/validDateOfBirth";

const t = inject("t");
const userStore = useUserStore();

const { registerErrors } = storeToRefs(userStore);
const { register } = userStore;

const props = defineProps({
  newsletterSignup: {
    type: Boolean,
    default: false
  },
  email: {
    type: String,
    default: ""
  },
  flashMessage: {
    type: String,
    default: null
  },
  signupLocation: {
    type: String,
    default: ""
  }
});

const user = reactive({
  email: props.email,
  password: "",
  first_name: "",
  last_name: "",
  date_of_birth: "",
  newsletter: props.newsletterSignup,
  location: "Countdown Sign Up",
  weddingAddOns: false,
  post_register_flash_message: props.flashMessage
});

const submitRegisterForm = () => {
  if (user.date_of_birth !== "" && !validDateOfBirth(user.date_of_birth)) {
    registerErrors.value.date_of_birth = [
      t("register-modal.form.date-of-birth-invalid")
    ];
  } else {
    registerErrors.value.date_of_birth = [];
    if (props.flashMessage) {
      window.localStorage.setItem("showRegistrationSuccess", true);
    }
    register(user);
  }
};
</script>
