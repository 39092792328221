import { ready } from "../utils/listeners";
import Carousel from "./initialiseCarousel";

ready(() => {
  const categoryCarousel = new Carousel(
    ".carousel-dot-category-header",
    ".category-header-slide"
  );

  categoryCarousel.init();
});
