import Flickity from "flickity";
import Cookies from "js-cookie";
import { on, ready } from "../utils/listeners";

window.initializeRecentlyViewedHeaderCarousel = () => {
  // Loop through and set up any product carousels
  // (product page, landing page, the fold)
  const carousels = document.querySelectorAll(
    ".recently-viewed-products-header-carousel"
  );

  carousels.forEach(carousel => {
    const options = {
      pageDots: true,
      contain: true,
      cellAlign: "left",
      groupCells: true,
      imagesLoaded: true,
      cellSelector: ".mini-carousel-cell",
      watchCSS: true,
      arrowShape:
        "M2.896 52.164A4.716 4.716 0 0 1 .6 48a4.716 4.716 0 0 1 2.292-4.164l41.82-42.464a4.572 4.572 0 0 1 6.536 0c1.8 1.832 1.8 4.804 0 6.636L11.86 48l39.388 39.992c1.8 1.832 1.8 4.8 0 6.636a4.572 4.572 0 0 1-6.536 0L2.896 52.16v.004z",
      // These events work around ios 11.3+ bug
      // https://github.com/metafizzy/flickity/issues/740
      on: {
        dragStart() {
          document.ontouchmove = e => {
            e.preventDefault();
          };
        },

        dragEnd() {
          document.ontouchmove = () => {
            return true;
          };
        }
      }
    };

    const flkty = new Flickity(carousel, options);

    const renderDots = () => {
      document.querySelector(".custom-page-dots").innerHTML =
        flkty.pageDots.dots
          .map((el, i) => `<li class='dot' data-index='${i}'></li>`)
          .join("");

      const dots = document.querySelectorAll(".custom-page-dots li");

      if (dots.length > 0) {
        dots[flkty.selectedIndex].classList.add("is-selected");

        dots.forEach(dot => {
          dot.addEventListener("click", e => {
            flkty.select(e.target.dataset.index);
          });
        });
      }
    };

    renderDots();
    flkty.on("select", renderDots);
    flkty.on("resize", () => {
      const isSingleSlide = flkty.slides.length < 2;
      carousel.classList.toggle("is-single-slide", isSingleSlide);
      renderDots();
    });

    // Trigger a resize event to
    flkty.resize();
  });
};

ready(() => {
  window.initializeRecentlyViewedHeaderCarousel();

  on("click", ".js-dismiss-recently-viewed-header", () => {
    Cookies.set("dismissedRecentlyViewedHeader", true, {
      expires: 3650
    });
  });
});
