import { createApp } from "vue";
import { createPinia } from "pinia";

import I18n from "../plugins/i18n";
import ManualRegisterWrapper from "../components/marketing/RegisterForm.vue";

window.showManualRegisterForm = email => {
  const nodes = document.querySelectorAll(".shared-manual-register");
  if (!nodes.length) return;

  nodes.forEach(node => {
    const props = JSON.parse(node.dataset.props);
    props.email = email;
    const translations = JSON.parse(node.dataset.translations);

    const pinia = createPinia();
    const app = createApp(ManualRegisterWrapper, props);

    app.use(pinia);

    app.use(I18n, translations);

    app.mount(node);
  });
};
