import { throttle } from "lodash-es";

document.addEventListener("DOMContentLoaded", () => {
  const gnav = document.querySelector(".gnav");
  const body = document.querySelector("body");

  const toggleNavClasses = () => {
    if (body.classList.contains("fold-homepage")) {
      gnav.classList.toggle("bg-paper-2", window.scrollY > 30);
      gnav.classList.toggle("text-white", window.scrollY < 30);
    } else if (window.location.pathname.includes("/thefold")) {
      gnav.classList.toggle("fold-swap", window.scrollY > 30);
    } else if (gnav) {
      // non fold pages
      gnav.classList.toggle("swap", window.scrollY > 30);
    }
  };

  toggleNavClasses();
  window.addEventListener("scroll", throttle(toggleNavClasses, 100));
});
