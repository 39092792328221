import { ready } from "../utils/listeners";

ready(() => {
  const slider = document.querySelector("[data-slider]");
  const overlay = document.querySelector(".slider-overlay");

  if (!slider || !overlay) return;

  slider.addEventListener("input", event => {
    const { value } = event.target;
    overlay.style.width = `${value}%`;
  });

  slider.addEventListener("change", () => {
    analytics.track("Interacted With Advent Calendar Image Slider");
  });

  const updateSliderForScreen = () => {
    const isDesktop = window.matchMedia("(min-width: 992px)").matches;
    slider.value = isDesktop ? 10 : 13;
    overlay.style.width = `${slider.value}%`;
  };

  updateSliderForScreen();
});
